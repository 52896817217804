@import 'styles/global.scss';

.editButton {
    background-color: $submitButtonBackgroundColor;
    box-shadow: 0px 8px 16px $submitButtonShadowColor;
    grid-column: 3/3;
    justify-self: end;
    width: 48px;
    transition-duration: 0.25s;

    &:not(.disabled):hover {
        box-shadow: 0px 4px 12px $submitButtonShadowColor;
        transform: translateY(4px);
    }
}

.groupPanel {
    border-radius: 14px;
    display: flex;
    flex-direction: column;
}

.header {
    // background-color: $tableHeaderBackgroundColor;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 35px;
    width: 100%;
}

.noGroupMembers {
    display: flex;
    align-items: center;
    justify-content: center;
}

.userList {
    align-items: flex-start;
    display: grid;
    gap: 18px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content max-content max-content;
    // grid-template-rows: 29px repeat(auto-fill, 48px);
    padding: 10px 35px;
}

.title {
    grid-column: 1/3;
}

.user {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    // grid-column: 1/3;
    grid-template-columns: 48px auto;
    grid-template-rows: 48px;
    padding: 12px;
}