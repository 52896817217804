@import './mixins/breakpoints.scss';

$inter: 'Inter', sans-serif;
$neuePlak: 'Neue Plak', sans-serif;

@mixin hugeFont {
    font-family: $neuePlak;
    font-size: 48px;
}

@mixin largestFont {
    font-family: $inter;
    font-size: 20px;
}

@mixin largerFont {
    font-family: $inter;
    font-size: 18px;
    font-weight: 400;
}

@mixin largeFont {
    font-family: $inter;
    font-size: 16px;
}

@mixin mediumLargeFont {
    @include largeFont;

    font-weight: 500;
}

@mixin normalFont {
    font-family: $inter;
    font-size: 14px;
}

@mixin boldLargestFont {
    @include largestFont;

    font-weight: 700;
}

@mixin boldLargeFont {
    @include largeFont;

    font-weight: 700;
}

@mixin boldNormalFont {
    @include normalFont;

    font-weight: 500;
}

@mixin bolderNormalFont {
    @include normalFont;

    font-weight: 700;
}

@mixin smallFont {
    font-family: $inter;
    font-size: 10px;
}

@mixin smallerFont {
    font-family: $inter;
    font-size: 12px;
}
