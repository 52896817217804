@import 'styles/global.scss';

.activeSlide {
    position: relative;
}

.carouselSlides {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    overflow: hidden;
    width: 100%;
}
