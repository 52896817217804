@import 'styles/global.scss';

.titleListItemM {
    background-color: $caseTableHeaderBackgroundColor;
    grid-template-columns: 1fr 1fr 1fr 40px;

    @include tabletAndAbove {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 40px;
    }
}

.titleListItemMultiTenant {
    background-color: $caseTableHeaderBackgroundColor;
    grid-template-columns: 1fr 1fr 1fr 1fr 40px;

    @include tabletAndAbove {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 40px;
    }
}

.headerText {
    &:nth-of-type(n + 4) {
        display: none;
    }

    @include tabletAndAbove {
        &:nth-of-type(n + 4) {
            display: table-cell;
        }
    }
}
