@import 'styles/global.scss';

.inputLabel {
    background-color: white;
    border: none;
    left: 10px;
    padding: 5px;
    width: 40%;
    position: absolute;
    top: -14px;
}

.inputWithLabel {
    background-color: white;
    position: relative;
    width: 100%;
    height: 54px;
    border-radius: $borderRadius;
    border: 1px solid $inputBorderColor;
}
