@import 'styles/global.scss';

.icon{
    border: 1px solid $borderColor;
    border-radius: $contentPanelBorderRadius;
    box-shadow: 0px 8px 16px $hollowButtonShadowColor;
    cursor: pointer;
    display: inline;
    height:28px;
    margin-bottom: -6px;
    margin-left: 5px;
    padding:6px;
    width:auto;

    &:hover {
        box-shadow: 0px 4px 12px $hollowButtonShadowColor;
        transform: translateY(2px);
    }
}

.iconComponent{
    width:auto;
}