@import 'styles/global.scss';

.administration {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    width: 100%;
}

@include mobileOnly {
    .administration {
        flex-direction: column;
    }
}
