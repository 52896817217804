@import 'styles/global.scss';

.documentManagementTableHeader {
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 70px 70px 40px;
}

.documentManagementTableHeaderMulti {
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 70px 70px 40px;
}
