@import 'styles/global.scss';

.panelBody {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
    justify-content: space-between;
}

// .formPreview{
//     display: inline;
// }

.buttonize,
.checkIcon,
.closeIcon {
    height:15px;
    width:15px;
    display:inline;
}

.titleLine {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.addFormDetailsPanel {
    height:min-content;
    // display: grid;
    // grid-template-rows: max-content auto;
    // grid-template-columns: auto;
    // height: 100%;
}

.conditionalPopUp{
    padding:24px;
}

.conditionalPanel {
    display: grid;
    grid-row: 2/3;
}

.fileUpload {
    grid-column: 1/2;
}

.radioButtons {
    margin-top: 10px;
}

.icon{
    fill: $gray !important;
    cursor: grab !important;
}

.elementContainer{
    display: grid;
    grid-template-columns: 25px auto 25px;
    cursor:default;
}

.conditionalContainer{
    display: grid;
    grid-template-columns: 25px auto 25px 25px;
    cursor:default;
}

.sectionWithBorder{
    margin: 5px;
    width: calc(90% - 10px);
}

.moveIconComponent{
    cursor: pointer !important;
}

.closeIconComponent{
    cursor: pointer !important;
}

// .conditionalsContainer{
//     grid-column: 2/3;
// }

.editButton {
    @include bolderNormalFont;

    box-shadow: 0px 8px 16px $submitButtonShadowColor;
    transition-duration: 0.25s;

    &:not(.disabled):hover {
        box-shadow: 0px 4px 12px $submitButtonShadowColor;
        transform: translateY(4px);
    }
}

.cancelButton {
    @include bolderNormalFont;

    box-shadow: 0px 8px 16px $submitButtonShadowColor;
    transition-duration: 0.25s;

    &:not(.disabled):hover {
        box-shadow: 0px 4px 12px $submitButtonShadowColor;
        transform: translateY(4px);
    }
}

.saveButton {
    @include bolderNormalFont;

    background-color: $submitButtonBackgroundColor;
    box-shadow: 0px 8px 16px $submitButtonShadowColor;
    color: $submitButtonTextColor;
    transition-duration: 0.25s;

    &:not(.disabled):hover {
        box-shadow: 0px 4px 12px $submitButtonShadowColor;
        transform: translateY(4px);
    }
}

.buttonList{
    display: grid;
    gap: 5px;
    grid-template-columns: max-content max-content max-content;
    justify-content: space-between;
}