@import 'styles/global.scss';

.positiveBarListItem {
    background-color: $positiveBarListItemBackgroundColor;
    border-color: $positiveBarListItemBorderColor;

    &:hover {
        background-color: $positiveBarListItemActiveBackgroundColor;
        border-color: $positiveBarListItemActiveBorderColor;
    }
}
