@import 'styles/global.scss';

.centeredLink {
    padding-left: 50px;
}
.item {
    padding-right: 20px;
}
.notificationItem {
    display: grid;
    border-radius: 8px;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 15px;

    &:hover {
        background-color: $menuHoverBackgroundColor;
        cursor: pointer;
    }
}

.selected {
    background-color: $menuListItemActiveBackgroundColor;
}

.option {
    display: none;
}

.subItem {
    padding-left: 50px;
}