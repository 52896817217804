@import 'styles/global.scss';

.upcomingItems {
    color: $upcomingItemsTitleColor;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
}

.title {
    position: absolute;
}
