@import 'styles/global.scss';

.administrationActivityTypesTableRow {
    gap: 50px;
    grid-template-columns: 200px 100px 1fr 40px;
}

.detailsButton {
    justify-self: end;
}

.link {
    color: $textColor;
}
