@import 'styles/global.scss';

.panelHeader {
    border-bottom: 1px solid $borderColor;
    display: grid;
    gap: 20px;
    padding: 24px 10px;
}

@include tabletAndAbove {
    .panelHeader {
        padding: 24px 25px 14.5px;
    }
}
