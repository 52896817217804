@import 'styles/global.scss';

.editButton {
    grid-column: 2/3;
}

.entityTagList {
    margin-left: 10px;
}

.entityTags {
    display: grid;
    gap: 15px;
    justify-content: space-between;
}
