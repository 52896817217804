@import 'styles/global.scss';

.activityTableHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 20px;
}

.cancelSaveButtons {
    grid-column: 1/3;
    justify-content: center;
}

.editor {
    grid-column: 1/3;
}

.formDeleteButton {
    fill: black;
}

.formTableRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 20px;
}

.panelTitle {
    grid-column: 1/3;
    justify-self: center;
}

.wide {
    @include tabletAndAbove {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.wideField {
    grid-column: 1/3;
}