@import 'styles/global.scss';

.activitiesPanel {
    display: grid;
    grid-template-rows: max-content auto;
    padding-bottom: 24px;

    @include mobileOnly {
        max-height: 400px;
    }
}
