@import 'styles/global.scss';

.case {
    display: grid;
    gap: $layoutGap;
    grid-template-columns: auto;
    grid-template-rows: 55px auto;
    height: 100%;
    width: 100%;
}

.meta {
    @include desktopAndAbove {
        display: none;
    }
}

.tabToggle {
    > [data-selected-item='details'] [data-value='details'],
    > [data-selected-item='history'] [data-value='history'],
    > [data-selected-item='meta'] [data-value='meta'] {
        @include activeTab;
    }

    @include desktopAndAbove {
        > [data-selected-item='meta'] [data-value='details'] {
            @include activeTab;
        }
    }
}

.withPageTitle {
    align-items: center;
    display: grid;
    gap: 14px;
    grid-template-columns: auto max-content max-content max-content;
    grid-template-rows: 40px;
    width: 100%;
}

.pdf-export {
    width: 210mm; /* A4 size width */
    height: auto;
    padding: 10mm;
    background: white;
}

@media print {
    .pdf-export {
        width: 100%;
        height: auto;
        padding: 0;
        background: white;
    }
}
