@import 'styles/global.scss';

.critical {
    color: $riskCriticalColor;

    input:checked {
        &::after {
            background-color: $riskCriticalColor;
        }

        &::before {
            border-color: $riskCriticalColor;
        }
    }
}

.high {
    color: $riskHighColor;

    input:checked {
        &::after {
            background-color: $riskHighColor;
        }

        &::before {
            border-color: $riskHighColor;
        }
    }
}

.low {
    color: $riskLowColor;

    input:checked {
        &::after {
            background-color: $riskLowColor;
        }

        &::before {
            border-color: $riskLowColor;
        }
    }
}

.medium {
    color: $riskMediumColor;

    input:checked {
        &::after {
            background-color: $riskMediumColor;
        }

        &::before {
            border-color: $riskMediumColor;
        }
    }
}

.radioButtons {
    padding-left: 10px;
}

.riskLevelRadioButtons {
    display: grid;
    gap: 12px;
}
