@import 'styles/global.scss';

.peopleTableRow {
    gap: 30px;
    grid-template-columns: 20px 1fr 0.6fr 0.6fr 100px 1fr 100px 5px 5px 5px;
}

.detailsButton {
    grid-column: 10/11;
    justify-self: end;
}

.statusIcon {
    width: $smallIconSize;
}
