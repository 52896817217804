@import 'styles/global.scss';

.buttonize,
.checkIcon {
    grid-column: 4/5;
    grid-row: 2/3;
}

.checkIcon {
    z-index: 1;
}

.closeIcon {
    grid-column: 2/3;
    grid-row: 2/3;
}

.contentBox,
.textBox {
    grid-column: 1/6;
    grid-row: 1/4;
}

.inTableRow {
    border: none !important;
    gap: 0;
    padding:0;
}

.editableContentBox {
    display: grid;
    grid-template-columns: 1fr $smallIconSize 5px $smallIconSize 10px;
    grid-template-rows: 10px $smallIconSize 1fr;

    &:not(:hover) {
        .buttonize {
            display: none;
        }
    }
}
