@import 'styles/global.scss';

.dots {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, 5px);
    grid-template-rows: 5px;
    height: 100%;
    justify-content: center;
    width: 100%;
}
