@import 'styles/global.scss';

.pillGroup {
    gap: 7px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
        flex: 0 1;
    }
}
