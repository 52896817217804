@import 'styles/global.scss';

.overdueSummarizedListItem {
    grid-template-columns: 1fr 15% 15% 1fr 20px 20px 20px;
}

.completing {
    background-color: $positiveBarListItemActiveBackgroundColor !important;
    border-color: $positiveBarListItemActiveBorderColor !important;
    height:0px;
    padding-top:0px;
    padding-bottom:0px;
    overflow:hidden;
    -webkit-transition: all 750ms;
    -moz-transition: all 750ms;
    -o-transition: all 750ms;
    -ms-transition: all 750ms;
    transition: all 750ms;
}
