@import 'styles/global.scss';

.entityTags {
    grid-column: 1/2;
}

.list {
    grid-column: 1/2;
}

.panelBody {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
