@import 'styles/global.scss';

.filename {
    @include smallFont;
    @include lineclamp;

    align-self: flex-end;
    font-weight: 500;
    margin: 5px;
    word-break: break-all;
}
