@import 'styles/global.scss';

.panelBody {
}

.table {
    grid-column: 1/8;
    grid-row: 3/3;
}

.wrapFirstRowFilters{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: max-content auto;
    column-gap: 25px;
    grid-column: 1/8;
    grid-row: 1/2;
}

.wrapSecondRowFilters{
    display: grid;
    grid-template-columns: 250px 1fr 1fr 1fr 1fr;
    grid-template-rows: max-content auto;
    column-gap: 25px;
    grid-column: 1/8;
    grid-row: 2/2;
}

.dateCustomContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 20px;
    p{
        font-weight: 500;
        margin: 0;
        font-size: 16px;
    }
}

.dateCustomWrap {
    display: flex;
    gap: 16px;
}

.dateCustomAddDates {
    display: flex;
    flex-direction: column;
    gap: 8px;
    p{
        font-weight: 300;
        margin: 0;
        font-size: 14px;
    }
    input {
        border: 1px solid $borderColor;
    }
}

.dateCustomAddDatesButtons {
    display: flex;
    gap: 8px;
}
