@import 'styles/global.scss';

.dropdownMenu {
    width: fit-content;
}

.input {
    cursor: pointer;

    &.hasError {
        background-color: $errorBackgroundColor;
    }
}
