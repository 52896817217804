@import 'styles/global.scss';

.dynamicTableRow {
    grid-template-rows: 20px;
}

.detailsButton {
    grid-column: 5/6;

    @include tabletAndAbove {
        grid-column: 8/9;
    }
}

.entities {
    display: none;

    @include tabletAndAbove {
        display: inline-block;
    }
}

.importantLink {
    display: none;

    @include tabletAndAbove {
        display: inline-block;
        color: $bodyTextColor;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
    }
}

.originatedWithLink {
    color: #3565e1;
    cursor: pointer;
}

.originatedWithoutLink {
    cursor: default;
}

.caseTitleLink {
    font-weight: 600;
    cursor: pointer;
}

.riskPill {
    display: none;
    grid-column: 6/7;

    @include tabletAndAbove {
        display: inline-block;
    }
}

.titleLink {
    font-weight: 600;
}

.overdueHover{
    &:hover {
        border-left-style: solid;
        border-left-width: 4px;
        background-color: $negativeBarListItemBackgroundColor;
        border-color: $negativeBarListItemBorderColor;
    }
}

.pendingHover{
    &:hover {
        border-left-style: solid;
        border-left-width: 4px;
        background-color: $infoBarListItemBackgroundColor;
        border-color: $infoBarListItemBorderColor;
    }
}

.closedHover{
    &:hover {
        border-left-style: solid;
        border-left-width: 4px;
        background-color: $positiveBarListItemBackgroundColor;
        border-color: $positiveBarListItemBorderColor;
    }
}

.text{
    text-align: center;
}