@import 'styles/global.scss';

.actionButton {
    @include bolderNormalFont;

    gap: 5px;
    height: 30px;
    margin-top: 0;
    padding: 4px 10px 4px 10px;
    white-space: nowrap;
}
