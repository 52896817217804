.activityUserList {
    align-items: center;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 29px;
    grid-template-rows: 29px repeat(auto-fill, 48px);
}

.editButton {
    grid-column: 2/3;
    justify-self: end;
}

.title {
    grid-column: 1/2;
}

.user {
    grid-column: 1/3;
    grid-template-columns: 48px auto;
    grid-template-rows: auto;
}