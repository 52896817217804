@import 'styles/global.scss';

.draftVersionButton {
    color: $draftVersionButtonTextColor;
    border-color: $draftVersionButtonBorderColor;

    &.selected {
        background-color: $draftVersionButtonInverseBackgroundColor;
    }
}
