@import 'src/styles/global.scss';

.radioButton {
    appearance: none;
    background-color: $formElementBackgroundColor;
    border-radius: 50%;
    border: 0;
    color: currentColor;
    cursor: pointer;
    display: flex;
    font: inherit;
    height: 20px;
    margin: 0;
    place-content: center;
    transform: translateY(-0.075em);
    width: 20px;

    &::before {
        /* Windows High Contrast Mode */
        border-radius: 50%;
        border: 2.5px solid $formElementBorderColor;
        content: '';
        height: 15px;
        width: 15px;
    }

    &::after {
        opacity: 0;
        transition: 120ms transform ease-in-out;
        transform: scale(1);
    }

    &:checked::after {
        align-self: center;
        background-color: $radioButtonBulletColor;
        border-radius: 50%;
        content: '';
        height: 50%;
        justify-self: center;
        opacity: 1;
        position: absolute;
        transform: scale(1);
        width: 50%;
    }

    &:hover {
        // border-color: $formElementHoverBorderColor;
        &::before {
            box-shadow: 0 0 0 10px $radioButtonHoverColor;
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &::before {
            background-color: $disableCheckedRadioButton;
            border-color: $formElementBorderColor;
        }
    }

    &:checked {
        &:disabled::before {
            background-color: $disableCheckedRadioButton;
            border-color: $formElementBorderColor;
        }

        opacity: 1;
    }
}

.isDisabled {
    cursor: not-allowed;
    opacity: 0.5;
}
