@import 'styles/global.scss';

.activitiesViewSelectorContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: end;
}

.activitiesViewSelector {
    display: grid;
    gap: 10px;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 120px;
    grid-template-rows: auto auto;
    width: 100%;
}

.activitiesViewSelectorSummarized {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 120px 120px;
    width: 100%;
}

.toggleWrap{
    grid-column: 9/9;
    grid-row: 1/3;
}

.flaggedToggle {
    grid-column: 9/9;
    grid-row: 1/2;
}

.allMeToggle {
    grid-column: 9/9;
    grid-row: 1/3;
}

.flaggedToggleSummarized {
    grid-column: 2/3;
}

.allMeToggleSummarized {
    grid-column: 3/4;
}

.bulkEditButton {
    margin: 0 auto;
    grid-row: 1/3;
}

.calendarLink {
    opacity: 50%;
    grid-column: 1/2;
    grid-row: 1/3;
}

.listLink {
    opacity: 50%;
    grid-column: 2/3;
    grid-row: 1/3;
}

.categoryDropdown, .startDate, .endDate{
    grid-row: 1/3;
}

.search{
    grid-row: 1/3;
    width: 100%;
    grid-column: 2/9;
}