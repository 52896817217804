@import 'styles/global.scss';

.attachments {
    grid-column: 1/4;
}

.activityDetailsPanel {
    display: grid;
    grid-template-rows: max-content;
    height: 100%;
}

.activityQuestions {
    grid-column: 1/4;
}

.activityTypeRiskPill {
    grid-column: 2/3;
    grid-row: 2/3;
}

.addQuestions {
    grid-column: 1/4;
}

.comments {
    grid-column: 1/4;
}

.dropdowns {
    display: grid;
    grid-column: 1/4;
    grid-template-columns: 1fr 1fr 1fr;
}

.fileUpload {
    grid-column: 1/4;
}

.instructions {
    grid-column: 1/4;
}

.multipleWithLabel{
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 5px;
    align-items: flex-start;
}

.panelBody {
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
}

.panelBody > :nth-child(2) {
    grid-template-columns: 1fr 16px 5px 16px 10px;

    > :first-child {
        grid-column: 1/6;
        grid-row: 1/4;
    }
}

.policyReference {
    color: $activityPolicyTextColor;
}


.policyReferenceWithLink {
    grid-column: 1/2;
    color: #3565e1;
    cursor: pointer;
}

.policyReferenceWithoutLink {
    cursor: default;
}

.policyReferenceRiskPill {
    grid-column: 2/3;
}

.riskLevelRadioButtons {
    color: $inactiveTextColor;
    grid-column: 1/4;
}

.sectionWithBorder {
    display: grid;
    grid-column: 1/4;
    grid-template-columns: 1fr 1fr 1fr;
}

.topPanelDetails {
    display: grid;
    grid-template-columns: max-content 1fr max-content 1fr max-content;
}

.withLabel {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    grid-template-rows: max-content max-content;
    align-items: flex-start;
}

.wrapperButtonize {
    grid-column: 4;
    grid-row: 2;
}

// .topPanelDetails {
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr;
//     grid-column: 1 / 4;
// }

.link{
    color: $green;
    text-decoration: none;
}