@import 'styles/global.scss';

.textarea {
    @include formElement;

    resize: none;

    &::placeholder {
        color: $inputPlaceholderColor;
    }

    &.hasError {
        background-color: $errorBackgroundColor;
    }
}
