@import 'styles/colors.scss';

.requestsTable {
    display: flex;
    flex-direction: column;
    gap: 30px;
		border-radius: 0;
}

.tableFooter {
    display: none;
}

.titleListItem {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.titleListItemMultiTenant {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.titleCell {
    position: relative;
    left: -20px;
    text-transform: capitalize;
}

.draft {
    color: $tableInfoAlternativeTitleTextColor;
}

.approved {
    color: $tablePositiveTitleTextColor;
}

.withdrawn {
    color: $tableInfoTitleTextColor;
}
