@import 'styles/global.scss';

.importantLink {
    @include largeFont;

    color: $bodyTextColor;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
}
