@import 'styles/global.scss';

.documentManagementTableRow {
    gap: 30px;
    grid-template-columns: 1fr 150px 150px 1fr 1fr 70px 40px;
}

.documentManagementTableRowMultiTenant {
    gap: 30px;
    grid-template-columns: 1fr 150px 150px 150px 1fr 1fr 70px 40px;
}

.version {
    justify-self: center;
}

.detailsButton {
    height: 18px;
    justify-self: end;
    width: 25px;
}

.link {
    color: $textColor;
    cursor: pointer;
}

.statusIcon {
    justify-self: center;
    width: $smallIconSize;
}
