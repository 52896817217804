@import 'styles/global.scss';

.complianceLogEntry {
    display: grid;
    gap: 24px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 55px auto auto auto auto;
    height: 100%;
    width: 100%;
}

.head {
    grid-column: 1/3;
}

.meta {
    grid-column: 2/3;
    grid-row: 2/6;
}
