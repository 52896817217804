@import 'styles/global.scss';

.panelWithSplitPanel {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr minmax(auto, 1fr);
    grid-template-rows: auto;
    height: 100%;
    width: 100%;

    @include tabletAndBelow {
        grid-template-columns: 1fr;
    }
}
