@import 'styles/global.scss';

.bullet {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 20px;
    width: 20px;
}

.historyEntry {
    display: grid;
    gap: 4px 16px;
    grid-template-columns: 20px auto;
    grid-template-rows: auto auto;
    position: relative;
}

.importantText {
    grid-column: 2/3;
    grid-row: 1/2;
}

.subTitle {
    grid-column: 2/3;
    grid-row: 2/3;
}

.verticalDivider {
    bottom: -10px;
    height: unset;
    left: 10px;
    position: absolute;
    top: 30px;
}
