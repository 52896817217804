@import 'styles/global.scss';

.caseTable {
    display: table;
    padding: 0 8px;
    // grid-template-columns: 1fr 1fr 1fr 48px;
    // grid-template-rows: repeat(auto-fill, 48px);
    // // grid-template-columns: repeat()

    // @include tabletAndAbove {
    //     grid-template-columns: 1fr 1fr 1fr 1fr 80px 1fr 40px;
    // }
}

.panelBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
}

.chartsWrapper{
    grid-column: 1/5;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
}

.tableFooter {
    display: none;
    // grid-template-columns: max-content 1fr;
}

.dateCustomContainer {
    grid-column: 1/5;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 20px;
    p{
        font-weight: 500;
        margin: 0;
        font-size: 16px;
    }
}

.dateCustomWrap {
    display: flex;
    gap: 16px;
}

.dateCustomAddDates {
    display: flex;
    flex-direction: column;
    gap: 8px;
    p{
        font-weight: 300;
        margin: 0;
        font-size: 14px;
    }
    input {
        border: 1px solid $borderColor;
    }
}

.dateCustomAddDatesButtons {
    display: flex;
    gap: 8px;
}
