@import 'styles/global.scss';

.withDownloadButton {
    align-items: center;
    display: grid;
    gap: 14px;
    grid-template-columns: 40px max-content;
    grid-template-rows: 40px;
    width: 100%;
    z-index: 9999;
}
