@import 'styles/global.scss';

.cancelSaveButtons {
    justify-content: center;
}

.createLogEntryForm {
    display: flex;
    flex-direction: column;
    gap: $layoutGap;
}
