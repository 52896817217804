@import 'styles/global.scss';

.assignedToMe {
    border-color: $infoTextColor;
    color: $infoTextColor;
}

.requestStatus {
    @include normalFont;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;

    @include desktopAndAbove {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.underReview {
    border-color: $alertTextColor;
    color: $alertTextColor;
}

.openRequests {
    border-color: $positiveTextColor;
    color: $positiveTextColor;
}

@include tabletAndBelow {
    .underReview {
        display: none;
    }
}
