@import 'styles/global.scss';

.buttonizeRight {
    grid-column: 3/4;
    grid-row: 1/2;
}

.carousel {
    display: grid;
    grid-template-columns: 10px 1fr 10px;
    grid-template-rows: 1fr 20px;
    width: 100%;
}

.carouselSlides {
    grid-column: 2/3;
    grid-row: 1/2;
}

.dots {
    grid-column: 2/3;
    grid-row: 2/3;
    padding-top: 10px;
}

.previousButton {
    grid-column: 1/2;
    grid-row: 1/2;
}
