@import 'styles/global.scss';

.addButton {
    @include bolderNormalFont;

    display: grid;
    gap: 5px;
    grid-template-columns: 15px;
    height: 100%;
    margin-top: 0;
    white-space: nowrap;
}

@include mobileOnly {
    .addButton {
        justify-content: center;
    }

    .span {
        display: none;
    }
}

@include tabletAndAbove {
    .addButton {
        grid-template-columns: 15px 1fr;
        padding: 4px 12px 4px 10px;
    }
}
