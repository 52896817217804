@import 'styles/global.scss';

.withDownloadButton {
    align-items: center;
    display: grid;
    gap: 14px;
    grid-template-columns: 40px max-content;
    grid-template-rows: 40px;
    width: 100%;
}

.pdf-export {
    width: 210mm; /* A4 size width */
    height: auto;
    padding: 10mm;
    background: white;
}

@media print {
    .pdf-export {
        width: 100%;
        height: auto;
        padding: 0;
        background: white;
    }
}
