@import 'styles/global.scss';

.overlay {
    @include centerBoth;

    background-color: $overlayBackgroundColor;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 2;

    &.show {
        visibility: visible;
    }
}
