@import 'styles/global.scss';

.closeButton {
    margin-top: -1px;
}

.tag {
    @include normalFont;

    background-color: red;
    color: $tagTextColor;
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 17px;
    height: min-content;
    padding: 8px 12px;
    border-radius: 20px;
}
