@import 'styles/global.scss';

.inputLabel {
    @include normalFont;

    background-color: $inputBackgroundColor;
    border-radius: $borderRadius;
    border: 1px solid $inputBorderColor;
    color: $bodyTextColor;
    margin: 0;
    padding: 0;
    pointer-events: none;
    text-align: left;

    &.hasFocus {
        border-color: $focusedInputBorderColor;
        box-shadow: 0 0 0 1px $focusedInputBorderColor;
    }

    &.disabled {
        border-color: rgb(240, 240, 240) !important;
    }
}

.legend {
    @include smallerFont;

    background-color: $inputBackgroundColor;
    color: $inputLabelLegendTextColor;
    margin-left: 8px;
    padding: 0 4px;
    white-space: nowrap;

    &.hasFocus {
        color: $focusedInputLabelColor;
    }

    &.disabled {
        color:lightgray !important
    }
}

.isRequired{
    color: red;
}