@import 'styles/global.scss';

.imageContainer {
    height: 30px;
    position: relative;
}

.avatar1 {
    position: absolute;
}

.avatar2 {
    left: 5px;
    position: absolute;
    opacity: 70%;
}

.avatar3 {
    left: 10px;
    position: absolute;
    opacity: 30%;
}

.image {
    height: 100%;
    width: 100%;
}
