@import 'styles/global.scss';

.activityMetaPanel {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}

.activityUserList {
    grid-column: 1 / 3;
}

.advancedSettings{
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: small !important;
    font-weight: 200 !important;
    border: none !important;
}

.frequency{
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr 29px;
}

.frequencyText{
    grid-column: 1/2;
}

.entityTags {
    grid-column: 1/3;
}

.list {
    grid-column: 1 / 3;
}

.panelBody {
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}

.sectionWithBorder {
    grid-column: 1 / 3;
    padding-top: 12px;
}

.activityLinkages {
    max-height: 200px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }
}

.scrollOverflow{
    overflow: visible;
}

.completed{
    color: black;
}

.future{
    color: $blue;
}

.current{
    color: $green;
}