@import './colors.scss';
@import './fonts.scss';
@import './theme.scss';
@import 'mixins/activeTab.scss';
@import 'mixins/animations.scss';
@import 'mixins/breakpoints.scss';
@import 'mixins/center.scss';
@import 'mixins/form.scss';
@import 'mixins/lineclamp.scss';
@import 'mixins/order.scss';

* {
    box-sizing: border-box;
    font-family: $inter;
}

::-webkit-scrollbar {
    background: $backgroundColor;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: $dominantColor;
    border-radius: 14px;
}

body {
    background-color: $backgroundColor;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-stretch: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-thumb {
        border: 4px solid $whiteSmoke;
    }
}

body,
html {
    height: 100%;
}

button {
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

p {
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

:global(#root) {
    height: 100%;
}