@import 'styles/global.scss';

.caseTable {
    display: table;
    padding: 0 2px;
    // grid-template-columns: 1fr 1fr 1fr 48px;
    // grid-template-rows: repeat(auto-fill, 48px);
    // // grid-template-columns: repeat()

    // @include tabletAndAbove {
    //     grid-template-columns: 1fr 1fr 1fr 1fr 80px 1fr 40px;
    // }
}

.tableFooter {
    display: none;
    // grid-template-columns: max-content 1fr;
}

.noItems {
    text-align: center;
}