@import 'styles/global.scss';

.actionMenuItem {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: 16px 1fr;
    grid-template-rows: 30px;
    justify-content: flex-start;
    padding: 10px 14px;
    text-align: left;

    &:hover {
        background-color: $actionMenuItemHoverBackgroundColor;
    }
}
