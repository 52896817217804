@import 'styles/global.scss';

.negativeBarListItem {
    background-color: $negativeBarListItemBackgroundColor;
    border-color: $negativeBarListItemBorderColor;

    &:hover {
        background-color: $negativeBarListItemActiveBackgroundColor;
        border-color: $negativeBarListItemActiveBorderColor;
    }
}
