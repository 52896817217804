@import 'styles/global.scss';

.notice {
    align-items: center;
    border: thin solid;
    border-radius: 10px;
    display: flex;
    justify-content: left;
    padding: 11px 22px;
    width: 100%;
}
