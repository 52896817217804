@import 'styles/global.scss';

.panelBody {
    display: grid;
    gap: 30px;
    height: 100%;
    overflow-y: auto;
    padding: 24px 10px;
    width: 100%;
		z-index: 0;

    &.loading {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    @include tabletAndAbove {
        padding: 24px 25px;
    }
}
