@import 'styles/global.scss';

.activityUserListItem {
    align-items: center;
    display: grid;
    gap: 2px 16px;
    grid-template-columns: 48px 1fr;
    grid-template-rows: 24px 24px;
}

.avatar {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 48px;
}

.title {
    align-self: end;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.status {
    align-self: start;
    color: $activityUserStatusColor;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    text-transform: capitalize;

    &.complete {
        color: $activityUserCompletedColor;
    }
    &.completedLate {
        color: $activityUserCompletedLateColor;
    }
    &.pastDue {
        color: $activityUserPastDueColor;
    }
}
