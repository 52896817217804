@mixin centerBoth {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin centerHorizontal {
    display: flex;
    align-items: center;
    justify-content: center;
}
