@import 'styles/global.scss';

.content {
    align-self: flex-start;
    grid-column: 1/2;
    grid-row: 2/3;
}

.header {
    align-self: flex-start;
    grid-column: 1/2;
    grid-row: 1/2;
}

.mainLayout,
.mainLayoutCollapsed {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 92px auto;
    height: 100%;
}

.navigationPanel {
    display: none;
}

@include desktopAndAbove {
    .content {
        grid-column: 2/3;
    }

    .header {
        grid-column: 2/3;
    }

    .contentFull {
        grid-column: 1/3;
    }

    .headerFull {
        grid-column: 1/3;
    }

    .mainLayout {
        grid-template-columns: 280px auto;
    }

    .mainLayoutCollapsed {
        grid-template-columns: 75px auto;
    }

    .navigationDrawer {
        display: grid;
    }

    .navigationPanel {
        box-shadow: -4px 0px 24px $drawerShadowColor;
        display: grid;
        grid-row: 1/3;
        overflow-x: hidden;
    }
}
