@import 'styles/global.scss';

.contentPanel {
    border: 1px solid #e6e8f0;
    box-shadow: -5px 10px 8px #00000046;
    max-height: 70vh;
    overflow: auto;
    padding: 5px 0;
}

.overlay {
    background-color: $transparent;
}
