@import 'styles/global.scss';

.downloadButton {
    @include bolderNormalFont;

    border-radius: 50%;
    gap: 5px;
    height: 100%;
    margin-top: 0;
    padding: 35% 20%;
    width: 100%;
}
