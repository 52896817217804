@import 'styles/global.scss';

.labeledToggle {
    gap: 10px;
}

.bodyText {
    color: $textColor;
    grid-column: 1/2;
    text-transform: capitalize;
}

.isActive {
    color: $activeColor;
}

.sublabel {
    grid-column: 1/4;
    grid-row: 2/3;
}

.toggle {
    grid-column: 2/3;
    width: 30px;
}
