@import 'styles/global.scss';

.activityItems {
    padding: 16px 0;
}

.cancelSaveButtons {
    justify-content: center;
}

.createLogEntryModal {
    padding: 12px 0;
}

.panelTitle {
    justify-self: center;
}

.withLabel {
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
}