@import 'styles/global.scss';

.activeVersionButton {
    color: $activeVersionButtonTextColor;
    border-color: $activeVersionButtonBorderColor;

    &.selected {
        background-color: $activeVersionButtonInverseBackgroundColor;
    }
}
