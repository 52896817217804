@import 'styles/global.scss';

.archivedVersionButton {
    color: $archivedVersionButtonTextColor;
    border-color: $archivedVersionButtonBorderColor;

    &.selected {
        background-color: $archivedVersionButtonInverseBackgroundColor;
    }
}
