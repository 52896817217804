@import 'styles/global.scss';

.panelWithAsidePanel {
    display: grid;
    gap: 24px;
    grid-template-rows: auto;
    height: 100%;
    width: 100%;

    @include tabletAndBelow {
        grid-template-columns: 1fr !important;
    }
}