@import 'styles/global.scss';

.knob {
    background-color: $lightMediumGray;
    border-radius: 10px;
    box-shadow: 0px 1px 2px $slideToggleKnobShadowColor;
    height: 16px;
    left: -1px;
    top: 3;
    position: relative;
    transition-delay: 0;
    transition-duration: 0.5s;
    transition-property: left;
    width: $smallIconSize;

    &:hover {
        cursor: pointer;
    }

    &.isInactive {
        left: calc(50% + 1px);
        background-color: $slideToggleKnobColor;
    }

    &.isInactiveOrange {
        left: calc(50% + 1px);
        background-color: $orange;
    }
}

.slideToggle {
    @include normalFont;

    align-items: center;
    background-color: $slideToggleBackgroundColor;
    border-radius: 7px;
    color: $bodyTextColor;
    display: flex;
    height: 12px;
    margin-top: 3px;
    width: 100%;

    &.isInactive {
        left: calc(50% + 1px);
        background-color: $slideToggleBackgroundGreen;
    }

    &.isInactiveOrange {
        left: calc(50% + 1px);
        background-color: $lightOrange;
    }
}
