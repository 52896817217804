@import 'styles/global.scss';

.htmlContent {
    @include normalFont;

    max-width: 100%;

    p {
        line-height: 20px;
        white-space: normal;
    }
}
