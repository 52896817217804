.collapseIcon {
    transform: rotate(90deg);
}

.expandIcon {
    transform: rotate(270deg);
}

.hidden {
    visibility: hidden;
}