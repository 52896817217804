@import 'styles/global.scss';

.contentPanel {
    max-height: 30vh;
    overflow: auto;
    padding: 5px 0;
}

.overlay {
    background-color: $transparent;
}
