@import 'styles/global.scss';

.complianceLog {
    display: grid;
    gap: $layoutGap;
    grid-template-columns: auto;
    grid-template-rows: 55px max-content 1fr;
    height: 100%;
    width: 100%;
}

.logEntriesPanel {
    grid-row: 3/4;
}

.overviewPanel {
    grid-row: 2/3;
}
