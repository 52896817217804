@import 'styles/global.scss';

.loader {
    animation: rotation 10s infinite linear;
    cursor: pointer;
    display: block;
    grid-column: 2/3;
    grid-row: 2/3;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.loaderContainer {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr min-content max-content 1fr;
}

.loaderText {
    grid-column: 2/3;
    grid-row: 3/4;
    text-align: center;
}