@import 'styles/global.scss';

.panelBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: max-content auto;
    gap: 25px;
}

.table {
    grid-column: 1/8;
    grid-row: 3/3;
}

.tableMultiTenant {
    grid-column: 1/9;
    grid-row: 3/3;
}

.search {
    grid-column: 1/8;
    grid-row: 1/2;
}

.noItems {
    margin-top: 24px;
    text-align: center;
}
