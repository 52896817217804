@import 'styles/global.scss';

.cancelSaveButtons {
    justify-content: center;
}

.largeTitle {
    justify-self: center;


}

.withLabel {
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
}