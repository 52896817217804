@import 'styles/global.scss';

.dot {
    background-color: $dotBackgroundColor;
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.isActive {
    background-color: $dotActiveBackgroundColor;
}
