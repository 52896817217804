@import 'styles/global.scss';

.bubbleWithText {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: 48px auto;
    grid-template-rows: 48px;
}

.bubbleWithText_link{
    cursor: pointer;
}
