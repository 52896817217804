@import 'styles/global.scss';

.withPageTitle {
    align-items: center;
    display: grid;
    gap: 14px;
    grid-template-columns: auto max-content;
    grid-template-rows: 40px;
    width: 100%;
}
