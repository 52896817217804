@import 'styles/global.scss';

.entityTags {
    grid-column: 1/3;
}

.list {
    grid-column: 1/3;
}

.panelBody {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}

.sectionWithBorder {
    grid-column: 1 / 3;
    padding-top: 12px;
}