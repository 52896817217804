@import 'styles/global.scss';

.menuListItem {
    background-color: $menuListItemBackgroundColor;
    border-color: $menuListItemBorderColor;
    display: grid;
    grid-template-columns: 30px auto;

    &:hover {
        background-color: $menuListItemHoverBackgroundColor;
    }
}

.isActive {
    background-color: $menuListItemActiveBackgroundColor;
    border-color: $menuListItemActiveBorderColor;

    &:hover {
        background-color: $menuListItemActiveBackgroundColor;
    }
}
