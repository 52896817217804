@import 'styles/global.scss';

.addVersionLink {
    grid-column: 3/4;
}

.versionButtons {
    grid-column: 1/4;
}

.versions {
    display: grid;
    gap: 14px;
    grid-template-columns: max-content 1fr min-content;
    width: 100%;
}
