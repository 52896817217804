@import 'src/styles/global.scss';

.checkboxWithLabel {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 10px;
}

.label {
    cursor: pointer;
}

.disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
