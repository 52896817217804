@import 'styles/global.scss';

.home {
    display: flex;
    gap: $layoutGap;
    height: 100%;
    width: 100%;
}

@include mobileOnly {
    .home {
        flex-direction: column;
    }
}

@include tabletAndAbove {
    .activitiesPanel {
        grid-column: 1/2;
        grid-row: 1/3;
    }

    .activitiesPanelFullWidth {
        grid-column: 1/3;
        grid-row: 1/3;        
    }

    .casePanel {
        grid-column: 2/3;
        grid-row: 3/4;
    }

    .formsPanel,
    .formsPanelOverlay {
        grid-column: 2/3;
        grid-row: 1/2;
    }

    .formsPanelNoMarketing {
        grid-column: 2/3;
        grid-row: 1/3;
    }

    .glancePanel {
        grid-column: 1/2;
        grid-row: 3/4;
    }

    .glancePanelFullWidth {
        grid-column: 1/3;
        grid-row: 3/4;
    }

    .home {
        display: grid;
        grid-template-columns: 1.15fr 0.85fr;
        grid-template-rows: 385px 150px 375px auto;
    }

    .marketingPanel,
    .marketingPanelOverlay {
        grid-column: 2/3;
        grid-row: 2/3;
    }

    .marketingPanelFullWidth,
    .marketingPanelOverlay {
        grid-column: 2/3;
        grid-row: 3/3;
    }
}
