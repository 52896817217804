@import 'styles/global.scss';

.inputWithTags {
    align-items: center;
    display: grid;
    grid-template-columns: max-content 1fr;
    padding-left: 12px;
    position: relative;
    width: 100%;
}
