@import 'styles/global.scss';

.panelTitle {
    justify-self: center;
}

.table {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.updateDatesHeader {
    text-align: center;
}

.updateDatesRow {
    padding: 0 20px;
    text-align: center;
}
