@import 'styles/global.scss';

.effectiveDate {
    grid-column: 3/4;
}

.description {
    grid-column: 1/4;
}

.doublePanel {
    grid-column: 1/4;
}

.fileInput {
    grid-column: 1/4;
}

.fullWidth {
    grid-column: 1/4;
}

.policyForm {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content min-content 1fr;
    height: 100%;
}

.policyNameInput {
    grid-column: 1/3;
}

.visibleTo {
    grid-column: 1/4;
}
