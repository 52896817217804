@import 'styles/global.scss';

.addPerson {
    display: grid;
    gap: $layoutGap;
    grid-template-columns: 30% auto 230px;
    grid-template-rows: 55px auto;
    height: 100%;
    width: 100%;
}

.cancelSaveButtons {
    grid-column: 3/4;
}

.pageTitle {
    grid-column: 1/2;
}

.personalInformationPanel {
    grid-column: 1/4;
}

.uploadPhotoPanel {
    grid-column: 1/2;
    grid-row: 2/3;
}
