@import 'styles/global.scss';

.errorMessage {
    grid-row: 3/4;
}

.panelTitle {
    grid-column: 1/3;
}

.submitButton {
    grid-row: 4/5;
}

.textInput {
    grid-row: 2/3;
}
