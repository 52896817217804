@import 'styles/global.scss';

.hollowButton {
    @include bolderNormalFont;

    background-color: $hollowButtonBackgroundColor;
    box-shadow: 0px 8px 16px $hollowButtonShadowColor;
    border: 1px solid $hollowButtonBorderColor;
    color: $hollowButtonTextColor;
    transition-duration: 0.25s;

    &:hover {
        box-shadow: 0px 4px 12px $hollowButtonShadowColor;
        transform: translateY(4px);
    }
}
