@import 'styles/global.scss';

.textLabel {
    grid-column: 1/-1;
}

.withLabel {
    display: grid;
    grid-template-rows: min-content min-content;
    gap: 15px;
    width: 100%;
}
