@import 'styles/global.scss';

.componentOverlay {
    @include centerBoth;

    backdrop-filter: blur(2px);
    background: $componentOverlayBackgroundColor;
    border-radius: $contentPanelBorderRadius;
    grid-column: 1/-1;
    grid-row: 1/-1;
    position: relative;
    z-index: 1;
}
