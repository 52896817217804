@import 'styles/global.scss';

.addRequestButton {
    grid-column: 4/5;
}


.marketingPanel {
    width: 100%;
}

.panelBody {
    gap: 20px;
    grid-template-columns:  max-content max-content 1fr max-content;
    grid-template-rows: max-content auto;
    width: 100%;
}

.panelTitle {
    grid-column: 1/3;
}

.requestStatus {
    grid-column: 1/5;
}