@import 'styles/global.scss';

.attachmentListItem {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    max-width: 100%;
    overflow: hidden;

    &:not(:hover) {
        .iconsContainer {
            display: none;
        }
    }

    .fileIcon {
        flex-shrink: 0;
        height: 25px;
        width: 20px;
    }

    .fileName {
        flex-grow: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
    }

    .input {
        border: 1px solid $inputBorderColor;
        height: 30px;
        width: 100%;
    }

    .metadata {
        @include normalFont;
        flex-shrink: 0;
        color: $bodyTextColor;
        line-height: 1.5;
    }

    .iconsContainer,
    .iconsEditContainer {
        flex-shrink: 0;
        width: 50px;
        display: flex;
        margin: 2px 0 0;
        align-items: center;
        justify-content: space-between;

        .deleteIcon,
        .editIcon {
            cursor: pointer;
            height: 16px;
            width: 16px;
        }
    }
}
