@import 'styles/global.scss';

.addCaseButton {
    grid-column: 4/-1;
    grid-row: 1/2;
    max-height: 25px;
    max-width: 150px;
}

.casePanel {
    width: 100%;
}

.donutPanel {
    grid-column: 1/-1;
}

.panelBody {
    gap: none;
    grid-template-columns: max-content max-content 1fr max-content;
    grid-template-rows: max-content 1fr;
    width: 100%;
}

.subPanel{
    gap:0;
    grid-column: 1/-1;
    grid-row: 2/3;
    grid-template-rows: max-content 1fr;
    padding: 0;
}

.table {
    grid-column: 1/8;
}

.viewAllLink {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: center;
}
