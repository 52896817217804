@import 'styles/global.scss';

.avatar {
    grid-column: 5/6;
}

.header {
    align-items: center;
    background-color: $headerBackgroundColor;
    display: grid;
    gap: 30px;
    grid-template-columns: 35px auto 30px 30px 44px;
    grid-template-rows: 44px;
    height: 100%;
    padding: 24px 32px;
    width: 100%;
}

.logo{
    grid-column: 1/3;
    max-width: 150px;
    cursor: pointer;
}

.hamburger {
    grid-column: 1/2;

    @include desktopAndAbove {
        display: none;
    }
}

.notificationIcon {
    grid-column: 3/4;
}
