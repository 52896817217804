@import 'styles/global.scss';

.buttonize,
.checkIcon {
    grid-column: 4/5;
    grid-row: 2/3;
}

.checkIcon {
    z-index: 1;
}

.closeIcon {
    grid-column: 2/3;
    grid-row: 2/3;
}

.bodyText,
.textBox {
    box-sizing: border-box;
    width: 100%;
    grid-column: 1/6;
    grid-row: 1/4;
}

.editableText {
    display: grid;
    grid-template-columns: 1fr $smallIconSize 5px $smallIconSize 10px;
    grid-template-rows: 10px $smallIconSize 1fr;

    &:not(:hover) {
        .buttonize {
            display: none;
        }
    }
}
