@import 'styles/global.scss';

.activitiesViewSelector {
    grid-column: 1/6;
    grid-row: 2/3;
}

.addActivitiesPanelHeader {
    grid-template-rows: max-content;
}

.addActivityButton {
    grid-column: 4/6;
    grid-row: 1/2;
}

.viewAllLink {
    padding-top: 3px;
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: self-end;
}

.activitiesViewSelectorContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: end;
}

.activitiesViewSelector {
    display: flex;
    gap: 10px;
    width: 100%;
}

.activitiesViewSelectorSummarized {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 120px 120px;
    width: 100%;
}

.toggleWrap{
    width: 130px;
}

.bulkEditButton {
    margin: 0 auto;
    grid-row: 1/3;
}

.calendarLink {
    opacity: 50%;
    grid-column: 1/2;
    grid-row: 1/3;
}

.listLink {
    opacity: 50%;
    grid-column: 2/3;
    grid-row: 1/3;
}

.categoryDropdown, .startDate, .endDate{
    grid-row: 1/3;
}

.search{
    width: 100%;
}