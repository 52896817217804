@import 'styles/global.scss';

.dropdownItemWithPill {
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 68px;
    padding: 15px;

    &:hover {
        background-color: $menuHoverBackgroundColor;
        cursor: pointer;
    }
}
