@import 'styles/global.scss';

.content {
    @include normalFont;

    background-color: $contentBackgroundColor;
    border-radius: $borderRadius;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $tooltipTextColor;
    width: 100%;
    padding: 0px 10px 10px 10px;
}

.contentBox {
    max-width: 600px;
    overflow-wrap: normal;
}