@import 'styles/global.scss';

.asideMenu {
    grid-column: 1/4;
    grid-row: 2/3;
}

.logoContainer {
    align-self: center;
    grid-column: 2/3;
}

.logoContainerCollapsed {
    align-self: center;
    grid-column: 1/-1;
    max-width: 74px;
    padding: 0 3px;
    box-sizing: border-box;
}

.logo {
    width: 100%;
}

.navigationPanel {
    background-color: $navigationMenuBackgroundColor;
    border-right: 1px solid $navigationMenuBorderColor;
    display: grid;
    grid-template-columns: 1fr 155px 1fr;
    grid-template-rows: 205px auto;
    height: 100%;
    width: 100%;
}
