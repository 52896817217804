@import 'styles/global.scss';

.icon {
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $smallIconSize;
}

.input {
    border: 1px solid $borderColor;
    padding-left: 45px;
}

.searchInput {
    display: block;
    position: relative;
}
