@import 'styles/global.scss';

.addFormButton{
    grid-column: 5/6;
}

.allMeToggle {
    padding-top: 5px;
    grid-column: 4/5;
    grid-row: 1;
}

.formsHeader {
    grid-template-columns: max-content max-content 1fr 80px max-content;
    grid-template-rows: max-content;
}

.viewAllLink {
    padding-top: 3px;
    grid-column: 2/3;
    justify-self: self-end;
}
