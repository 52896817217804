// @import 'styles/global.scss';

// .formMetaPanel {
//     @include tabletAndBelow {
//         display: none;
//     }
// }

.addFormDetailsPanel {
    display: grid;
    grid-template-rows: max-content auto;
    grid-template-columns: max-content;
    height: 100%;
}

.conditionalPanel {
    display: grid;
    grid-row: 2/3;
}

// .fileUpload {
//     grid-column: 1/2;
// }

// .radioButtons {
//     margin-top: 10px;
// }

// .icon{
//     fill: $gray !important;
//     cursor: grab !important;
// }

// .elementContainer{
//     display: grid;
//     grid-template-columns: 25px auto 25px;
//     cursor:default;
// }

// .sectionWithBorder{
//     margin: 5px;
//     width: calc(90% - 10px);
// }

// .draggable {
//     top: auto !important;
//     left: auto !important;
// }

// .closeIconComponent{
//     cursor: pointer !important;
// }

// .conditionalsContainer{
//     grid-column: 2/3;
// }