@import 'src/styles/global.scss';

.label {
    @include largeFont;

    user-select: none;
}

.isRequired{
    color: red;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}