@import 'styles/global.scss';

.panelBody {
    grid-template-rows: 40px 1fr;
    justify-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align: center;
    width: 600px;
}
